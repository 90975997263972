
import { defineComponent } from 'vue'
import { contactData } from '@/api/contactApi'
import breakpointsStore from '@/store/breakpoints'

export default defineComponent({
  name: 'FooterNav',

  setup() {
    return {
      isMobile: breakpointsStore.isMobile,
      phone: contactData.phone,
      email: contactData.email,
      linkedInUrl: contactData.linkedInUrl
    }
  }
})
