import { defineAsyncComponent } from 'vue'
import { trackRouter } from 'vue-gtag-next'
import { createRouter, createWebHashHistory, Router, RouteRecordRaw } from 'vue-router'

const routes: RouteRecordRaw[] = [
  {
    path: '/',
    name: 'home',
    component: defineAsyncComponent(
      () => import(/* webpackChunkName: "Home" */ '../views/HomeView.vue')
    )
  },
  {
    path: '/about',
    name: 'about',
    component: defineAsyncComponent(
      () => import(/* webpackChunkName: "About" */ '../views/AboutView.vue')
    )
  },
  {
    path: '/education',
    name: 'education',
    component: defineAsyncComponent(
      () => import(/* webpackChunkName: "Education" */ '../views/EducationView.vue')
    )
  },
  {
    path: '/help',
    name: 'help',
    component: defineAsyncComponent(
      () => import(/* webpackChunkName: "Help" */ '../views/HelpView.vue')
    )
  },
  {
    path: '/projects',
    name: 'projects',
    component: defineAsyncComponent(
      () => import(/* webpackChunkName: "Projects" */ '../views/ProjectsView.vue')
    )
  },
  {
    path: '/components-2',
    name: 'components-2',
    component: defineAsyncComponent(
      () => import(/* webpackChunkName: "Components2" */ '../views/projects/Components2View.vue')
    )
  },
  {
    path: '/components-1',
    name: 'components-1',
    component: defineAsyncComponent(
      () => import(/* webpackChunkName: "Components1" */ '../views/projects/Components1View.vue')
    )
  },
  {
    path: '/breachguard',
    name: 'breachguard',
    component: defineAsyncComponent(
      () => import(/* webpackChunkName: "BreachGuard" */ '../views/projects/BreachGuardView.vue')
    )
  },
  {
    path: '/dealroom',
    name: 'dealroom',
    component: defineAsyncComponent(
      () => import(/* webpackChunkName: "Dealroom" */ '../views/projects/DealroomView.vue')
    )
  },
  {
    path: '/hackcheck',
    name: 'hackcheck',
    component: defineAsyncComponent(
      () => import(/* webpackChunkName: "HackCheck" */ '../views/projects/HackCheckView.vue')
    )
  },
  {
    path: '/holistance',
    name: 'holistance',
    component: defineAsyncComponent(
      () => import(/* webpackChunkName: "Holistance" */ '../views/projects/HolistanceView.vue')
    )
  },
  {
    path: '/passwords',
    name: 'passwords',
    component: defineAsyncComponent(
      () => import(/* webpackChunkName: "Passwords" */ '../views/projects/PasswordsView.vue')
    )
  },
  {
    path: '/petrapro',
    name: 'petrapro',
    component: defineAsyncComponent(
      () => import(/* webpackChunkName: "Petrapro" */ '../views/projects/PetraproView.vue')
    )
  },
  {
    path: '/spolujidlo',
    name: 'spolujidlo',
    component: defineAsyncComponent(
      () => import(/* webpackChunkName: "Spolujidlo" */ '../views/projects/SpolujidloView.vue')
    )
  },
  {
    path: '/skills',
    name: 'skills',
    component: defineAsyncComponent(
      () => import(/* webpackChunkName: "Skills" */ '../views/SkillsView.vue')
    )
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'error',
    component: defineAsyncComponent(
      () => import(/* webpackChunkName: "Error" */ '../views/ErrorView.vue')
    )
  }
]

const router: Router = createRouter({
  history: createWebHashHistory(),
  routes
})

trackRouter(router)

export default router
