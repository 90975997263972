<template>
  <div class="available-commands-button">
    <button
      class="link"
      aria-label="Toggle Available commands"
      @click="isPaletteVisible = !isPaletteVisible"
    >
      Available commands
    </button>
  </div>
  <div v-if="isPaletteVisible" class="available-commands">
    <nav>
      <span>Commands</span>
      <div class="control" @click="isPaletteVisible = false">
        <img src="@/assets/icons/close.svg" alt="Close" />
      </div>
    </nav>
    <div class="content">
      <button
        class="link"
        v-for="command in availableCommands"
        :key="command.name"
        :style="
          command.name === 'projects' || command.name === 'spolujidlo' || command.name === 'about'
            ? 'margin-bottom: 20px'
            : ''
        "
        @click="execute(command.name)"
      >
        {{ command.name }}<br />
      </button>
    </div>
  </div>
</template>

<script>
import cliCommandStore from '@/store/cliCommand'
import { ref } from 'vue'

export default {
  name: 'CLIPaletteDesktop',

  async setup() {
    const isPaletteVisible = ref(false)

    const execute = function (commandName) {
      cliCommandStore.commandName.value = commandName
      cliCommandStore.execute()
      isPaletteVisible.value = false
    }

    return {
      isPaletteVisible,
      execute,
      availableCommands: cliCommandStore.availableCommands
    }
  }
}
</script>

<style scoped>
.available-commands-button {
  min-width: 210px;
  margin-left: 20px;
}
nav {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: var(--cli-header-height);

  background: var(--building-color);
}
nav span {
  color: #000;
  font-size: 1.3rem;
  margin-left: 10px;
}
div.control {
  width: 30px;
  height: 30px;
  margin-right: 10px;

  cursor: pointer;
  transition: all 0.1s;
}
div.control:hover {
  opacity: 0.5;
}
.available-commands {
  position: absolute;
  right: 0;
  bottom: var(--cli-input-height);
  width: 250px;
}
.content {
  border-left: 2px solid var(--building-color);
  background: #000;
  padding: 1rem;
}
.content button {
  display: block;
  margin: 10px 0px;
}
</style>
