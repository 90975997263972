<template>
  <div class="command-line-input">
    <span class="ip">{{ ip }}:~$ </span>
    <input
      ref="commandInput"
      type="text"
      v-model="commandName"
      @keydown="onKeyDown"
      maxlength="30"
      aria-label="Type a command to navigate"
    />
    <CLIPaletteDesktop />
  </div>
</template>

<script>
import CLIPaletteDesktop from '@/components/cliControls/CLIPaletteDesktop'
import ipStore from '@/store/ip'
import cliCommandStore from '@/store/cliCommand'

export default {
  name: 'CLIInput',
  components: {
    CLIPaletteDesktop
  },

  mounted() {
    this.$refs.commandInput.focus()
  },

  async setup() {
    const onKeyDown = function (event) {
      if (event.keyCode === 13) {
        if (cliCommandStore.commandName.value) {
          cliCommandStore.execute()
        }
      }
    }

    return {
      ip: ipStore.clienIp,
      commandName: cliCommandStore.commandName,
      availableCommands: cliCommandStore.availableCommands,
      onKeyDown
    }
  }
}
</script>

<style scoped>
.command-line-input {
  position: absolute;
  bottom: 0;

  width: calc(100% - 2rem);
  height: calc(var(--cli-input-height) - 2rem);
  text-align: left;
  display: flex;

  padding: 1rem;
  border-top: 2px solid var(--building-color);
}
.ip {
  color: var(--blue-color);
}
input {
  width: 100%;
  height: 1.5rem;

  margin-top: -2px;
  margin-left: 10px;
  padding-bottom: 7px;

  font-size: 1.4rem;
  color: var(--text-color);
}
</style>
