import { ref, computed, Ref, ComputedRef } from 'vue'
import commandsApi from '@/api/commands'
import router from '@/router'
import { Command } from '@/types/command'

const availableCommands: Command[] = commandsApi.getCommands()
const commandName: Ref<string> = ref('')

const command: ComputedRef<Command | undefined> = computed(() =>
  availableCommands.find((command: Command): boolean => command.name === commandName.value)
)

const execute = function () {
  if (!command.value) {
    router.push({ name: 'error' })
  } else {
    if (command.value.isNavigation) {
      router.push({ name: command.value.name })
    }
    if (command.value.action) {
      command.value.action()
    }
  }

  commandName.value = ''
}

export default {
  commandName,
  availableCommands,
  execute
}
