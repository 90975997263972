import { Ref, ref } from 'vue'
import ipApi from '@/api/ipApi'

const clienIp: Ref<string> = ref('0.0.0.0')
const fetchClientIp = async function (): Promise<void> {
  clienIp.value = await ipApi.fetchClientIp()
}

export default {
  clienIp,
  fetchClientIp
}
