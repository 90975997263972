import { resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CLI = _resolveComponent("CLI")!
  const _component_FooterNav = _resolveComponent("FooterNav")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("main", {
      class: _normalizeClass([_ctx.isMobile ? 'mobile' : ''])
    }, [
      _createVNode(_component_CLI)
    ], 2),
    _createVNode(_component_FooterNav)
  ], 64))
}