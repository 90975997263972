
/* eslint-disable no-unused-vars */
import { defineComponent, Ref, ref, watch } from 'vue'
import CLINav from '@/components/cliControls/CLINav.vue'
import CLIInput from '@/components/cliControls/CLIInput.vue'
import CLIPaletteMobile from '@/components/cliControls/CLIPaletteMobile.vue'
import CloseDialog from '@/components/cliControls/CloseDialog.vue'
import cliUiStore from '@/store/cliUi'
import breakpointsStore from '@/store//breakpoints'

export default defineComponent({
  name: 'CLI',
  components: {
    CLINav,
    CLIInput,
    CLIPaletteMobile,
    CloseDialog
  },

  mounted() {
    cliUiStore.initializeWindowRestrictions()
    cliUiStore.initializeDefaultPosition()
  },

  setup() {
    const hasTransition: Ref<boolean> = ref(false)

    watch(cliUiStore.isMinimized, (): void => {
      if (cliUiStore.isMinimized.value) {
        hasTransition.value = true
      } else {
        setTimeout(() => {
          hasTransition.value = false
        }, 200)
      }
    })

    return {
      window,
      hasTransition,
      isMinimized: cliUiStore.isMinimized,
      isCloseDialog: cliUiStore.isCloseDialog,
      isLoading: cliUiStore.isLoading,
      isMobile: breakpointsStore.isMobile,
      breakpoint: breakpointsStore.breakpoint,
      position: cliUiStore.position,
      dimensions: cliUiStore.dimensions
    }
  }
})
