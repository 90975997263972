import { apiTimeout } from '@/api/apiUtils'
import { IpData } from '@/types/ip'

const IP_API_URL: string = 'https://ipv4.jsonip.com'

const fetchClientIp = async function (): Promise<string> {
  try {
    const result: Response | undefined = await Promise.race([fetch(IP_API_URL), apiTimeout])

    if (!result) {
      return '0.0.0.0'
    }

    const data: IpData = await result.json()
    return data.ip
  } catch (error) {
    console.log(error)
    return '0.0.0.0'
  }
}

export default {
  fetchClientIp
}
