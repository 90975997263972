import { ref, computed, ComputedRef, Ref } from 'vue'
import cliUiStore from '@/store/cliUi'
import breakpointsStore from './breakpoints'
import { Breakpoints } from '@/types/breakpoints'

const maxChars: Ref<number> = ref(0)
const charWidth: ComputedRef<number> = computed(() => {
  if (breakpointsStore.breakpoint.value === Breakpoints.XS) {
    return 18
  }
  if (breakpointsStore.breakpoint.value === Breakpoints.SM) {
    return 22.5
  }
  if (breakpointsStore.breakpoint.value === Breakpoints.MD) {
    return 27.5
  }
  return 28
})

const initializeRowWidth = function (): void {
  maxChars.value = Math.floor(cliUiStore.dimensions.x / charWidth.value) * 2
}

export default {
  maxChars,
  initializeRowWidth
}
