import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, Suspense as _Suspense, withCtx as _withCtx, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, Fragment as _Fragment, createElementBlock as _createElementBlock, normalizeStyle as _normalizeStyle } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CLINav = _resolveComponent("CLINav")!
  const _component_CLIInput = _resolveComponent("CLIInput")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_CLIPaletteMobile = _resolveComponent("CLIPaletteMobile")!
  const _component_CloseDialog = _resolveComponent("CloseDialog")!

  return (_openBlock(), _createElementBlock("section", {
    class: _normalizeClass([_ctx.hasTransition ? 'transition' : '', _ctx.isMobile ? 'mobile' : '']),
    style: _normalizeStyle(`top: ${_ctx.position.y}px; left: ${_ctx.position.x}px; width: ${_ctx.dimensions.x}px; height: ${_ctx.dimensions.y}px;`)
  }, [
    (!_ctx.isMobile)
      ? (_openBlock(), _createBlock(_component_CLINav, { key: 0 }))
      : _createCommentVNode("", true),
    (!_ctx.isMinimized)
      ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
          (!_ctx.isMobile && !_ctx.isLoading)
            ? (_openBlock(), _createBlock(_Suspense, { key: 0 }, {
                default: _withCtx(() => [
                  _createVNode(_component_CLIInput)
                ]),
                _: 1
              }))
            : _createCommentVNode("", true),
          _createElementVNode("div", {
            class: _normalizeClass(['cli-container', _ctx.isMobile ? 'mobile' : ''])
          }, [
            _createVNode(_component_router_view)
          ], 2),
          (_ctx.isMobile)
            ? (_openBlock(), _createBlock(_component_CLIPaletteMobile, { key: 1 }))
            : _createCommentVNode("", true),
          (_ctx.isCloseDialog)
            ? (_openBlock(), _createBlock(_component_CloseDialog, { key: 2 }))
            : _createCommentVNode("", true)
        ], 64))
      : _createCommentVNode("", true)
  ], 6))
}