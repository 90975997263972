<template>
  <nav :class="{ mac: isMac }" @mousedown="dragMouseDown">
    <template v-if="isMac">
      <div class="controls-container-mac">
        <div class="control" @click="isCloseDialog = true">
          <img src="@/assets/icons/close.svg" alt="Close" />
        </div>
        <div v-if="isMinimized" class="control" @click="maximizeCommandLine">
          <img src="@/assets/icons/maximize.svg" alt="Maximize" />
        </div>
        <div v-else class="control" @click="minimizeCommandLine">
          <img src="@/assets/icons/minimize.svg" alt="Minimize" />
        </div>
      </div>
      <span class="controls-container">doskar.me</span>
    </template>

    <template v-else>
      <span class="controls-container">doskar.me</span>
      <div class="controls-container">
        <div v-if="isMinimized" class="control" @click="maximizeCommandLine">
          <img src="@/assets/icons/maximize.svg" alt="Maximize" />
        </div>
        <div v-else class="control" @click="minimizeCommandLine">
          <img src="@/assets/icons/minimize.svg" alt="Minimize" />
        </div>
        <div class="control" @click="isCloseDialog = true">
          <img src="@/assets/icons/close.svg" alt="Close" />
        </div>
      </div>
    </template>
  </nav>
</template>

<script>
import cliUiStore from '@/store/cliUi'
import breakpointsStore from '@/store/breakpoints'
import routerStore from '@/store/router'

export default {
  name: 'CLINav',
  setup() {
    const isMac = navigator.platform.toUpperCase().indexOf('MAC') >= 0

    return {
      isMac,
      isMinimized: cliUiStore.isMinimized,
      isCloseDialog: cliUiStore.isCloseDialog,
      isMobile: breakpointsStore.isMobile,
      dragMouseDown: cliUiStore.dragStart,
      minimizeCommandLine: cliUiStore.minimize,
      maximizeCommandLine: cliUiStore.maximize,
      closeCommandLine: routerStore.close
    }
  }
}
</script>

<style scoped>
nav {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: var(--cli-header-height);

  background: var(--building-color);
  cursor: move;
}
nav.mac {
  justify-content: flex-start;
}

.controls-container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 10px;

  color: #000;
  font-size: 1.3rem;
}
.controls-container-mac {
  margin-right: 15px;
}
div.control {
  display: inline-block;
  width: 30px;
  height: 30px;
  margin-left: 10px;

  cursor: pointer;
  transition: all 0.1s;
}
.controls-container-mac .div.control {
  margin-left: 0px;
  margin-right: 0px;
}
div.control:hover {
  opacity: 0.5;
}
</style>
