import { ref, computed, ComputedRef, Ref } from 'vue'
import { Breakpoints } from '@/types/breakpoints'

const breakpoint: Ref<Breakpoints> = ref(Breakpoints.INITIAL)
const isMobile: ComputedRef<boolean> = computed(() => {
  return (
    breakpoint.value === Breakpoints.XS ||
    breakpoint.value === Breakpoints.SM ||
    breakpoint.value === Breakpoints.MD
  )
})

const initilizeBreakPoint = function (): void {
  const width: number = window.innerWidth
  const height: number = window.innerHeight

  if (width <= 400) {
    breakpoint.value = Breakpoints.XS
  } else if (width <= 600) {
    breakpoint.value = Breakpoints.SM
  } else if (width <= 1264 || height <= 850) {
    breakpoint.value = Breakpoints.MD
  } else if (width <= 1905) {
    breakpoint.value = Breakpoints.LG
  } else {
    breakpoint.value = Breakpoints.XL
  }
}

export default {
  breakpoint,
  isMobile,
  initilizeBreakPoint
}
