
import { defineComponent } from 'vue'
import CLI from '@/components/CLI.vue'
import FooterNav from '@/components/FooterNav.vue'

import breakpointsStore from '@/store/breakpoints'
import cliUiStore from '@/store/cliUi'
import cliUiRowStore from '@/store/cliRowUi'
import ipStore from '@/store/ip'
import debounce from 'lodash.debounce'

import '@/assets/reset.css'
import '@/assets/variables.css'

export default defineComponent({
  name: 'App',
  components: {
    CLI,
    FooterNav
  },

  created() {
    ipStore.fetchClientIp()
    breakpointsStore.initilizeBreakPoint()

    setTimeout(() => {
      cliUiStore.isArtificallyLoading.value = false
    }, 1000)
  },

  mounted() {
    const debouncedInit: Function = debounce((): void => {
      cliUiRowStore.initializeRowWidth()
      cliUiStore.isArtificallyLoading.value = false
    }, 1000)

    window.addEventListener('resize', () => {
      cliUiStore.isArtificallyLoading.value = true

      breakpointsStore.initilizeBreakPoint()
      cliUiStore.initializeWindowRestrictions()
      cliUiStore.initializeDefaultPosition()

      debouncedInit()
    })
  },

  setup() {
    return {
      isMobile: breakpointsStore.isMobile
    }
  }
})
