import cliUi from '@/store/cliUi'
import { Command } from '@/types/command'

const commands: Command[] = [
  { name: 'home', isNavigation: true },
  { name: 'projects', isNavigation: true },
  { name: 'skills', isNavigation: true },
  { name: 'education', isNavigation: true },
  { name: 'about', isNavigation: true },
  { name: 'help', isNavigation: true },
  {
    name: 'exit',
    isNavigation: false,
    action: () => {
      cliUi.isCloseDialog.value = true
    }
  }
]

const getCommands = function (): Command[] {
  return commands
}

export default {
  getCommands
}
