import { contactData } from '@/api/contactApi'

const onCloseUrl: string = document.referrer

const close = function (): void {
  if (onCloseUrl !== '' && !onCloseUrl.includes(window.origin)) {
    window.location.href = onCloseUrl
  } else {
    window.location.href = `https://www.${contactData.linkedInUrl}`
  }
}

export default {
  onCloseUrl,
  close
}
