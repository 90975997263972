import { Dimensions, Position, Restrictions } from '@/types/ui'
import { ComputedRef, Ref, computed, reactive, ref } from 'vue'
import breakpointsStore from './breakpoints'
import ipStore from './ip'

const defaultDimensions: ComputedRef<Dimensions> = computed(() => {
  return {
    x: breakpointsStore.isMobile.value ? 1 : 1350,
    y: breakpointsStore.isMobile.value ? 1 : 680
  }
})
const dimensions: Dimensions = reactive({
  x: defaultDimensions.value.x,
  y: defaultDimensions.value.y
})

const position: Position = reactive({ x: 0, y: 0 })
const clientPositionAtStart: Position = { x: 0, y: 0 }
const clientPosition: Position = { x: 0, y: 0 }

const isMinimized: Ref<boolean> = ref(false)
const isCloseDialog: Ref<boolean> = ref(false)
const isArtificallyLoading: Ref<boolean> = ref(true)

const isLoading: ComputedRef<boolean> = computed(() => {
  return isArtificallyLoading.value || !ipStore.clienIp.value
})

const windowRestriction: Restrictions = {
  top: 0,
  bottom: 0,
  left: 0,
  right: window.innerWidth - dimensions.x - 8
}

// Init actions
const initializeWindowRestrictions = function (): void {
  const footerHeight: number = document.querySelector('footer')?.getBoundingClientRect().height || 0
  const magicBorder: number = 8
  windowRestriction.bottom = window.innerHeight - dimensions.y - footerHeight - magicBorder
  windowRestriction.right = window.innerWidth - dimensions.x - magicBorder
}
const initializeDefaultPosition = function (): void {
  const OPTICALLY_BETTER: number = 50

  if (breakpointsStore.isMobile.value) {
    position.x = 0
    position.y = 16 //1rem

    dimensions.x = 0
    dimensions.y = 0

    const mainHeightPercent: number = Number(
      getComputedStyle(document.documentElement)
        .getPropertyValue('--main-height')
        .trim()
        .substring(0, 2)
    )
    const footerHeight: number = window.innerHeight * ((100 - mainHeightPercent) / 100)

    const commandPaletteHeight: number =
      Number(
        getComputedStyle(document.documentElement)
          .getPropertyValue('--cli-palette-height')
          .trim()
          .slice(0, 2)
      ) +
      2 * 16 // 2 x 1rem as padding

    dimensions.x = window.innerWidth
    dimensions.y = window.innerHeight - footerHeight - commandPaletteHeight
  } else {
    dimensions.x = defaultDimensions.value.x
    dimensions.y = defaultDimensions.value.y

    position.x = window.innerWidth / 2 - dimensions.x / 2
    position.y = window.innerHeight / 2 - dimensions.y / 2 - OPTICALLY_BETTER
  }
}

// General actions
const minimize = function (): void {
  isMinimized.value = true
  dimensions.x = 300
  dimensions.y = 40

  initializeWindowRestrictions()
  position.x = 40
  position.y = windowRestriction.bottom - 40
}

const maximize = function (): void {
  isMinimized.value = false
  dimensions.x = defaultDimensions.value.x
  dimensions.y = defaultDimensions.value.y

  initializeWindowRestrictions()
  initializeDefaultPosition()
}

// Dragging actions
const dragStart = function (event: DragEvent): void {
  event.preventDefault()

  if (isMinimized.value) return

  clientPositionAtStart.x = event.clientX
  clientPositionAtStart.y = event.clientY

  document.onmouseup = dragStop
  document.onmousemove = dragMove
}
const dragMove = function (event: MouseEvent): void {
  event.preventDefault()

  clientPosition.x = clientPositionAtStart.x - event.clientX
  clientPosition.y = clientPositionAtStart.y - event.clientY
  clientPositionAtStart.x = event.clientX
  clientPositionAtStart.y = event.clientY

  const newX: number = position.x - clientPosition.x
  const newY: number = position.y - clientPosition.y

  if (newX > windowRestriction.left && newX < windowRestriction.right) {
    position.x = newX
  }
  if (newY > windowRestriction.top && newY < windowRestriction.bottom) {
    position.y = newY
  }
}
const dragStop = function (): void {
  document.onmouseup = null
  document.onmousemove = null
}

export default {
  position,
  dimensions,
  isMinimized,
  isCloseDialog,
  isLoading,
  isArtificallyLoading,
  dragStart,
  minimize,
  maximize,
  initializeWindowRestrictions,
  initializeDefaultPosition
}
