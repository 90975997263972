<template>
  <div
    :class="['close-dialog', isMobile ? 'mobile' : '']"
    :style="`top: ${positionY}px; left: ${positionX}px;`"
  >
    <nav :class="{ mac: isMac }">
      <template v-if="isMac">
        <div class="control" @click="isCloseDialog = false">
          <img src="@/assets/icons/close.svg" alt="Close" />
        </div>
        <span class="controls-container">Close</span>
      </template>
      <template v-else>
        <span class="controls-container">Close</span>
        <div class="control" @click="isCloseDialog = false">
          <img src="@/assets/icons/close.svg" alt="Close" />
        </div>
      </template>
    </nav>
    <div class="content">
      <h1>
        Are you sure,<br />
        you want to quit?
      </h1>
      <div class="buttons-container">
        <button class="link" @click="handleClose">Yes</button>
        <button class="link" @click="isCloseDialog = false">No</button>
      </div>
    </div>
  </div>
</template>

<script>
import breakpointsStore from '@/store/breakpoints'
import { Breakpoints } from '@/types/breakpoints'
import cliUiStore from '@/store/cliUi'
import routerStore from '@/store/router'
import { computed } from 'vue'

export default {
  name: 'CloseDialog',

  setup() {
    const isMac = navigator.platform.toUpperCase().indexOf('MAC') >= 0
    const positionX = computed(() => {
      if (breakpointsStore.breakpoint.value === Breakpoints.XS) {
        return cliUiStore.dimensions.x / 2 - 125
      }
      return cliUiStore.dimensions.x / 2 - 150
    })
    const positionY = computed(() => {
      if (breakpointsStore.breakpoint.value === Breakpoints.XS) {
        return cliUiStore.dimensions.x / 2 - 125
      }
      return breakpointsStore.isMobile.value ? 30 : 100
    })

    const handleClose = function () {
      routerStore.close()
    }

    return {
      isMac,
      handleClose,
      isCloseDialog: cliUiStore.isCloseDialog,
      isMobile: breakpointsStore.isMobile,
      positionX,
      positionY
    }
  }
}
</script>

<style scoped>
.close-dialog {
  position: absolute;
  width: 300px;
  height: 200px;

  text-align: center;

  background: black;
  border: 4px solid var(--building-color);
  font-size: 1.3rem;
}
.close-dialog.mobile {
  left: 100%;
  border: 2px solid var(--building-color);
}
nav {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: var(--cli-header-height);

  background: var(--building-color);
}
nav.mac {
  justify-content: flex-start;
}
nav span {
  color: #000;
  font-size: 1.3rem;
  margin-left: 10px;
}
.content {
  padding: 1rem;
}
.buttons-container {
  display: flex;
  align-items: center;
  justify-content: center;
}
.buttons-container .link {
  margin: 20px;
}
div.control {
  width: 30px;
  height: 30px;
  margin-right: 10px;

  cursor: pointer;
  transition: all 0.1s;
}
nav.mac div.control {
  margin-left: 10px;
  margin-right: 10px;
}
div.control:hover {
  opacity: 0.5;
}

@media only screen and (max-width: 400px) {
  .close-dialog {
    width: 250px;
    height: 160px;
    margin: auto;
  }
  nav span {
    font-size: 1rem;
  }
  .link {
    font-size: 1rem;
  }
  button.control {
    width: 25px;
    height: 25px;
  }
}
</style>
