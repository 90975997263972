<template>
  <div class="command-palette">
    Available commands:
    <div class="commands-container">
      <button
        class="link"
        v-for="command in availableCommands"
        :key="command.name"
        @click="execute(command.name)"
      >
        {{ command.name }}
      </button>
    </div>
  </div>
</template>

<script>
import cliCommandStore from '@/store/cliCommand'

export default {
  name: 'CLIPaletteMobile',

  setup() {
    const execute = function (commandName) {
      cliCommandStore.commandName.value = commandName
      cliCommandStore.execute()
    }

    return {
      execute,
      availableCommands: cliCommandStore.availableCommands.filter(
        (command) =>
          command.name !== 'dealroom' &&
          command.name !== 'breachguard' &&
          command.name !== 'hackcheck' &&
          command.name !== 'passwords' &&
          command.name !== 'spolujidlo' &&
          command.name !== 'holistance' &&
          command.name !== 'petrapro'
      )
    }
  }
}
</script>

<style>
.command-palette {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  height: var(--cli-palette-height);
  clear: both;

  padding: 1rem;
  background: black;
  border-top: 2px solid var(--building-color);
}
.commands-container {
  margin-bottom: 15px;
  overflow: hidden;
}
.commands-container button {
  float: left;
  margin-right: 10px;
  margin-top: 10px;
  margin-bottom: 2px;
}

@media only screen and (max-width: 400px) {
  .command-palette {
    font-size: 1rem;
  }
}
</style>
